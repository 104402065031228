<template>
  <b-form-group>
    <v-select placeholder="Клиент" label="name" :filterable="false" :options="customers" v-model="order.customer"
              @search="onCustomerSearch" @option:selected="onCustomerSelected">
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          <p class="mb-0">Ничего не нашли для <em>{{ search }}</em></p>
          <p class="mb-2 mt-2 text-center">
            <b-button variant="success" @click="onNewCustomer">Создать нового клиента</b-button>
          </p>
        </template>
        <p v-else class="mb-0 text-secondary font-weight-light">Начните набирать имя или телефон клиента</p>
      </template>
      <template v-slot:option="option">
        <span class="text-monospace">{{ option.contact }}</span><span class="font-weight-light"> {{ option.name }} {{
              option.lastName
            }}</span>
        <p v-if="option.comment" class="mb-0 text-secondary font-weight-light">{{ option.comment }}</p>
      </template>
      <template v-slot:selected-option="option">
        {{ option.contact }} {{ option.name }} {{ option.lastName }}
      </template>
    </v-select>
    <b-form-invalid-feedback :state="validateCustomer">
      {{ serverValidationError == null ? "Выберите клиента" : serverValidationError }}
    </b-form-invalid-feedback>
    <pre v-if="order.customer" class="mb-0 mt-1 text-danger">{{ order.customer.comment }}</pre>
  </b-form-group>
</template>
<script>
import * as debounce from "lodash.debounce";
import {store} from "@/store";

export default {
  name: 'SelectCustomer',
  props: {
    customers: Array,
    order: Object,
    serverValidationError: String,
    validateCustomer: Boolean
  },
  methods: {
    onCustomerSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.customerSearch(loading, search, this);
      }
    },

    customerSearch: debounce((loading, search, vm) => {
      vm.$api.get("customers/search", {filter: search}).then(res => {
        vm.searchCustomer = search
        vm.customers = res
        loading(false)
      })
    }, 350),

    onCustomerSelected() {
      this.order.discount = this.order.customer.discount
    },

    onNewCustomer() {
      store.customers.showAddCustomer(
          {contact: this.searchCustomer},
          this.onCustomerCreated)
    },
  }
}
</script>
<style scoped>
>>> {
  --vs-search-input-placeholder-color: lightgray;
}
</style>
