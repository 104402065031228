<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="auto" class="mr-auto pl-1">
          <h2 class="mb-0">Заказы</h2>
        </b-col>
        <b-col cols="auto" class="pl-0 mt-1 pr-0">
          <b-button size="sm" class="mx-1" @click="add()">Новый</b-button>
          <b-button size="sm" class="mx-1" @click="addFast()">C улицы</b-button>
          <b-button size="sm" class="mx-1" @click="addWithForm()">Через форму</b-button>
        </b-col>
      </b-row>

      <b-row align-h="start">
        <b-col class="p-1">
          <b-button-toolbar class="mb-2">
            <b-form-radio-group
                class="mb-2 mr-2"
                v-model="tabModel"
                :options="[{text: 'Новые', value: 1}, {text: 'Архив', value: 2}, {text: 'Отказы', value: 3}]"
                button-variant="outline-primary"
                size="sm"
                buttons
                @change="onFilter"
            ></b-form-radio-group>

            <v-select v-if="tabModel === 1" class="select-customer pr-1 pb-1" placeholder="Кто собирает"
                      :options="floristsForFilter" v-model="selectedFlorist" label="firstName" clearable>
              <template v-slot:option="option">
                {{ option.firstName }} {{ option.lastName }}
              </template>
              <template v-slot:selected-option="option">
                {{ option.firstName }} {{ option.lastName }}
              </template>
            </v-select>
            <v-select v-if="tabModel === 1" class="select-customer pr-1 pb-1" placeholder="Курьер"
                      :options="couriersForSelect" v-model="selectedCourier" clearable>

            </v-select>
            <!--            disabled until holidays and work load-->
            <b-button v-if="tabModel === 1 && selectedFlorist != null" class="btn-primary p-2 pt-0 pb-0"
                      @click="downloadPdf()">Распечатать
            </b-button>
            <p class="ml-3 mb-2 lead d-none d-lg-block" v-if="tabModel === 1 && fastOrdersCount !== null">
              Заказов с улицы в этом месяце: {{ fastOrdersCount }}
            </p>
            <v-select v-if="tabModel === 2" class="select-customer pr-1 pb-1" placeholder="Клиент" label="name"
                      :filterable="false"
                      :options="customersForFilter" v-model="customerFilter"
                      @search="onCustomerSearch">
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <p class="mb-0">Ничего не нашли для <em>{{ search }}</em></p>
                </template>
                <p v-else class="mb-0 text-secondary font-weight-light">Начните набирать имя или телефон клиента</p>
              </template>
              <template v-slot:option="option">
                <span class="text-monospace">{{ option.contact }}</span><span class="font-weight-light"> {{
                  option.name
                }} {{ option.lastName }}</span>
                <p v-if="option.comment" class="mb-0 text-secondary font-weight-light">{{ option.comment }}</p>
              </template>
              <template v-slot:selected-option="option">
                {{ option.contact }} {{ option.name }} {{ option.lastName }}
              </template>
            </v-select>
            <b-form-datepicker v-if="tabModel === 2" class="datepicker" v-model="dateFilterFrom"
                               label-no-date-selected="Дата доставки от"
                               start-weekday="1"
                               today-button
                               reset-button
                               close-button>
            </b-form-datepicker>
            &nbsp;
            <b-form-datepicker v-if="tabModel === 2" class="datepicker" v-model="dateFilterTo"
                               label-no-date-selected="Дата доставки до"
                               start-weekday="1"
                               today-button
                               reset-button
                               close-button>
            </b-form-datepicker>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-container>

    <b-table :sticky-header="orderTableHeight" no-border-collapse responsive hover small
             :items="items" :fields="fields" primary-key="id"
             :tbody-transition-props="{name: 'orders'}">
      <template #cell(id)="data">
        <b-link @click="edit(data.item)" :class="{ 'text-decoration-line-through' : data.item.finalState }">
          {{ data.value }}
        </b-link>
        <b-link
            @click="copyFormLink(data.item.id, data.item.customer.bonus)">
          <b-icon icon="card-text"></b-icon>
        </b-link>
        <br>
        <b-link
            @click="copyHolidayFormLink(data.item.id, data.item.customer.bonus)">
          праздничная форма
        </b-link>
      </template>
      <template #cell(deliveryDate)="data">
        <div v-if="!data.item.deliveryDateUnknown">
          <p class="mb-0 text-nowrap">
            {{ data.item.deliveryDateStr }}
          </p>
          <p class="mb-0 text-nowrap" v-if="data.item.deliveryIntervalType === 1">
            {{ data.item.deliveryTypeDescr.name }} {{
              data.item.deliveryIntervalStart
            }}-{{ data.item.deliveryIntervalEnd }}
          </p>
          <p class="mb-0 text-nowrap" v-if="data.item.deliveryIntervalType === 2">
            {{ data.item.deliveryTypeDescr.name }} строго к {{ data.item.deliveryIntervalStart }}
          </p>
        </div>
        <p v-else>???</p>
        <div v-if="data.item.showDeliveryState">
          <b-link v-if="data.item.deliveryClaimStatus === 10" class="mb-0 text-nowrap"
                  @click="showChangeCourier(data.item)">Доставка еще не назначена
          </b-link>
          <b-link v-else-if="data.item.deliveryClaimStatus === 19" class="mb-0 text-nowrap text-success"
                  @click="showChangeCourier(data.item)">
            {{ couriers[data.item.courier] ? couriers[data.item.courier] : 'доставка заказана' }}
          </b-link>
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 11" class="mb-0 text-nowrap"-->
          <!--                  @click="showCancelDeliveryDialog(data.item)">Ожидание оффера-->
          <!--          </b-link>-->
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 12" class="mb-0 text-nowrap"-->
          <!--                  target="_blank" :href="getDeliveryLink(data.item)">Подтвердить доставку-->
          <!--          </b-link>-->
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 13" class="mb-0 text-nowrap"-->
          <!--                  @click="showCancelDeliveryDialog(data.item)">Поиск курьера-->
          <!--          </b-link>-->
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 15" class="mb-0 text-nowrap"-->
          <!--                  @click="showCancelDeliveryDialog(data.item)">Курьер в пути-->
          <!--          </b-link>-->
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 16" class="mb-0 text-nowrap"-->
          <!--                  @click="showCancelDeliveryDialog(data.item)">Некорректные данные-->
          <!--          </b-link>-->
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 17" class="mb-0 text-nowrap"-->
          <!--                  @click="showCancelDeliveryDialog(data.item)">Ошибка доставки, перезакажите-->
          <!--          </b-link>-->
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 18" class="mb-0 text-nowrap"-->
          <!--                  @click="showCancelDeliveryDialog(data.item)">Доставка отменена Яндексом-->
          <!--          </b-link>-->
          <!--          <b-link v-else-if="data.item.deliveryClaimStatus === 20" class="mb-0 text-nowrap"-->
          <!--                  @click="showCancelDeliveryDialog(data.item)">Заказ доставлен-->
          <!--          </b-link>-->
          <b-link
              v-else
              target="_blank"
              href="#">
            Неизвестный статус
          </b-link>
          <p class="mt-1 text-danger" v-if="data.item.warning !== null && data.item.warning !== ''">
            {{ data.item.warning }}</p>
        </div>
      </template>
      <template #cell(state)="data">
        <b-link :title="data.item.stateDesc.description" class="mb-0 text-nowrap" @click="changeState(data.item)">
          {{ data.item.stateDesc.name }}
        </b-link>
      </template>
      <template #cell(total)="data">
        <p class="mb-0 text-nowrap">
          <del v-if="data.item.discount > 0 || data.item.discountFixed > 0" class="text-secondary font-weight-light">
            {{ data.item.totalWithoutDeliveryCostWithoutDiscount - data.item.bonusRemoved - data.item.temporaryBonusRemoved}}
          </del>
          <span v-if="data.item.deliveryCost > 0">
            {{ data.item.totalWithoutDeliveryCost - data.item.bonusRemoved - data.item.temporaryBonusRemoved}} + {{ data.item.deliveryCost }} =
          </span>
          {{ data.item.total - data.item.bonusRemoved - data.item.temporaryBonusRemoved }}
        </p>
        <p v-if="data.item.prepaid > 0" class="mb-0 text-nowrap">{{ data.item.prepaid }}&nbsp;предоплата</p>
        <p class="mb-0 text-nowrap">
          <b-link title="Погасить"
                  :class="{'mb-0 text-nowrap': true, 'text-black-50': data.item.total - data.item.prepaid === 0}"
                  @click="showPayments(data.item)">
            {{ data.item.total - data.item.bonusRemoved - data.item.temporaryBonusRemoved - data.item.prepaid }}&nbsp;остаток
          </b-link>
        </p>
        <p v-if="data.item.deliveryCost === 0 && data.item.deliveryType !== 3" class="mb-0 text-danger">
          Согласовать доставку!
        </p>
      </template>
      <template #cell(createBy)="data">
        <p class="mb-0 text-nowrap">{{ data.value.firstName }}&nbsp;{{ data.value.lastName }}</p>
        <p class="mb-0 text-nowrap">{{ data.item.createDateStr }}</p>
        <p class="mb-0 text-nowrap text-secondary">Последнее обновление:</p>
        <p class="mb-0 text-nowrap text-secondary">{{
            data.item.updateBy.firstName
          }}&nbsp;{{ data.item.updateBy.lastName }}</p>
      </template>
      <template #cell(collectBy)="data">
        <b-link v-if="data.value" class="mb-0 text-nowrap" @click="changeCollector(data.item)">
          {{ data.value.firstName }}&nbsp;{{ data.value.lastName }}
        </b-link>
        <b-link v-else @click="changeCollector(data.item)">Назначить</b-link>
      </template>
      <template #cell(items)="data">
        <div v-bind:class="{'important rounded p-2': data.item.tags !== null && data.item.tags !== ''}">
          <div v-for="(item, itemIndex) in data.value" class="mb-0 text-nowrap">
            <div class="mb-0" v-if="item.product.id !== 1">
              <span v-if="data.value.length > 1">{{ itemIndex + 1 }}:</span>
              {{ products[item.product.id].name }}
              <a :href="products[item.product.id].productLink" target="_blank">
                <b-icon icon="camera"></b-icon>
              </a>&nbsp;
              {{ item.price }}
              <span v-if="item.amount > 1">&nbsp;{{ item.amount }}шт</span>
              <pre class="mb-0 item-description text-danger">({{ products[item.product.id].description }})</pre>
              <pre v-if="item.description" class="mb-0 item-description text-danger">{{ item.description }}</pre>
            </div>
            <!-- сободная позиция - название берем из описания -->
            <div class="mb-0" v-if="item.product.id === 1">
              <span v-if="data.value.length > 1">{{ itemIndex + 1 }}:&nbsp;</span>
              <pre class="mb-0 custom-item-name">{{ item.description }} {{
                  item.price
                }} {{ (item.amount > 1) ? item.amount + "шт" : "" }}</pre>
            </div>
          </div>
        </div>
      </template>
      <template #cell(customer)="data">
        <b-link class="mb-0 text-nowrap" @click="viewCustomer(data.value)">
          {{ data.value.name }} {{ data.value.lastName }}&nbsp;{{ data.value.contact }}
        </b-link>
        {{ data.item.contactTypeDescr }}
        <pre class="mb-0 item-description text-danger" v-html="highlightPhoneNumbers(data.value.comment)"></pre>
      </template>
      <template #cell(deliveryAddress)="data">
        <a class="mb-0 text-nowrap"
           :href="'https://yandex.ru/maps/2/saint-petersburg/search/' + encodeURIComponent(data.value)"
           target="_blank">{{ data.value }}</a>
        <p class="mb-0 text-nowrap text-secondary" v-if="data.item.region !== null">
          {{ data.item.region }} р-н
        </p>
        <p class="mb-0 text-nowrap">
          {{ data.item.deliveryContactName }}&nbsp;{{ data.item.deliveryContact }}
        </p>
        <pre class="mb-0 item-description text-danger">{{ data.item.comment }}</pre>
        <pre class="mb-0 item-description text-danger">{{ data.item.deliveryComment }}</pre>
      </template>
    </b-table>

    <AddOrder :product-search="productSearch"/>
    <FastAddOrder/>
    <ViewCustomer/>
    <ChangeState/>
    <Payments/>
    <ChangeCollector/>
    <LinkDelivery/>
    <AddOrderWithForm
        :copy-form-link="copyFormLink"
        :copy-holiday-form-link="copyHolidayFormLink"
        :product-search="productSearch"/>
    <CancelAutoDelivery/>
    <ChangeCourier/>

  </div>
</template>

<style>
.orders-move,
.orders-enter-active,
.orders-leave-active {
  transition: all 0.5s ease;
}

.orders-enter-from,
.orders-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.select-customer {
  min-width: 350px;
}

.datepicker {
  max-width: 200px;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.important {
  background-color: rgb(192, 201, 239);
}

.custom-item-name {
  display: inline-flex;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
}

.item-description {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
}

.itemsColumn {
  max-width: 500px;
  overflow: scroll;
}
</style>

<script>
import {store} from "@/store";
import AddOrder from "@/views/AddOrder";
import FastAddOrder from "@/views/FastAddOrder";
import ViewCustomer from "@/views/ViewCustomer";
import ChangeState from "@/views/ChangeState"
import ChangeCollector from "@/views/ChangeCollector";
import {consts} from "@/consts";
import * as debounce from "lodash.debounce";
import {formatLocalTime} from "@/utils";
import LinkDelivery from "@/views/LinkDelivery";
import AddOrderWithForm from "@/views/AddOrderWithForm";
import randomBytes from "randombytes";
import CancelAutoDelivery from "@/views/CancelAutoDelivery";
import Payments from "@/views/Payments";
import ChangeCourier from "@/views/ChangeCourier";

export default {
  components: {
    AddOrderWithForm,
    AddOrder,
    ChangeState,
    ViewCustomer,
    FastAddOrder,
    ChangeCollector,
    LinkDelivery,
    CancelAutoDelivery,
    Payments,
    ChangeCourier
  },
  props: {
    tab: {
      type: Number,
      default: 1
    },
    customerId: {
      type: Number,
      default: null
    }
  },

  watch: {
    customerFilter: function (newVal, oldVal) {
      this.onFilter()
    },

    dateFilterFrom: function (newVal, oldVal) {
      this.onFilter()
    },

    dateFilterTo: function (newVal, oldVal) {
      this.onFilter()
    },
    selectedFlorist: function (newVal, oldVal) {
      this.fetchData()
    },
    selectedCourier: function (newVal, oldVal) {
      this.fetchData()
    }
  },

  data() {
    return {
      orderTableHeight: "300px",
      orderTableHeightListener: null,
      tabModel: this.tab,
      floristsForFilter: [],
      selectedFlorist: null,
      selectedCourier: null,
      customerFilter: null,
      dateFilterFrom: null,
      dateFilterTo: null,
      customersForFilter: [],
      orders: {},
      customers: {},
      products: {},
      payments: [],
      items: [],
      fastOrdersCount: null,
      fields: [
        {label: 'Номер', key: 'id', stickyColumn: true},
        {label: 'Доставка', key: 'deliveryDate', stickyColumn: true},
        {label: 'Состояние', key: 'state'},
        {label: 'Оплата', key: 'total'},
        {label: 'Принимал', key: 'createBy'},
        {label: 'Собирает', key: 'collectBy'},
        //  disabled until holidays and work load
        {label: 'Товары', key: 'items', tdClass: "itemsColumn"},
        {label: 'Клиент', key: 'customer'},
        {label: 'Получатель', key: 'deliveryAddress'},
      ],
      global: store.orders,
      couriers: consts.orders.couriers,
      couriersForSelect: consts.orders.couriersArr,
    }
  },

  created() {
    if (this.customerId) {
      this.loadCustomerById(this.customerId).then(res => {
        this.customerFilter = res
      })
    } else {
      this.fetchData()
    }

    this.$api.loadFlorists(res => {
      this.floristsForFilter = res
    })
  },

  mounted() {
    const offset = 170;
    this.orderTableHeightListener = () => {
      this.orderTableHeight = (window.innerHeight - offset).toString() + 'px'
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.orderTableHeightListener)
    })

    this.orderTableHeightListener()

    this.wsListener = msg => {
      if (msg.event === 'reload') {
        this.fetchData()
      }
    };
    this.$api.addWebSocketListener(this.wsListener)
  },

  beforeDestroy() {
    this.$api.removeWebSocketListener(this.wsListener)
    window.removeEventListener('resize', this.orderTableHeightListener)
  },

  methods: {
    productSearch: debounce((loading, search, vm) => {
      vm.$api.get("products/search", {filter: search}).then(res => {
        vm.products = res
        loading(false)
      })
    }, 350),

    downloadPdf() {
      let params = {}
      if (this.dateFilterFrom) params.deliveryDateFrom = this.dateFilterFrom
      if (this.dateFilterTo) params.deliveryDateTo = this.dateFilterTo
      if (this.selectedFlorist) params.collectBy = this.selectedFlorist.phone
      this.$api.download("order/pdf", params)
    },

    async createFormLink(orderId, bonusAmount, link) {

      let token = randomBytes(16).toString('hex');
      await this.$api.postParams("order/token", {
        token: token,
        orderId: orderId
      })

      link += "token=" + token + "&bonus_amount=" + bonusAmount.toString() + "&bonus=2";
      return link;
    },
    async copyFormLink(orderId, bonusAmount) {
      let link = await this.createFormLink(orderId, bonusAmount, "https://forms.yandex.ru/cloud/643535d9d0468810d15c4b00?");
      await navigator.clipboard.writeText(link);

      this.$bvToast.toast(`Ссылка на форму скопирована`, {
        title: 'Форма',
        autoHideDelay: 1000,
        appendToast: true,
        solid: true
      })
    },

    async copyHolidayFormLink(orderId, bonusAmount) {
      let link = await this.createFormLink(orderId, bonusAmount, "https://forms.yandex.ru/cloud/65db96705d2a06fdcb79d272?");
      await navigator.clipboard.writeText(link);

      this.$bvToast.toast(`Ссылка на праздничную форму скопирована`, {
        title: 'Форма',
        autoHideDelay: 1000,
        appendToast: true,
        solid: true
      })
    },

    loadCustomerById(customerId) {
      if (!customerId) return;

      return this.$api.get("customers/" + customerId)
    },

    onCustomerSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.customerSearch(loading, search, this);
      }
    },

    customerSearch: debounce((loading, search, vm) => {
      vm.$api.get("customers/search", {filter: search}).then(res => {
        vm.customersForFilter = res
        loading(false)
      })
    }, 350),

    onFilter() {
      this.fetchData();
    },

    highlightPhoneNumbers(text) {
      if (text === null) {
        return null
      }
      const re = /((8|\+7)?\d{10}(\b|$))/g;

      return text.replaceAll(re, "<a href='api/customers/redirect/c2d?phone=$1'>$1</a>");
    },

    preprocessOrdersData: function (ordersData) {
      this.orders = ordersData.orders
      this.customers = ordersData.customers
      this.products = ordersData.products
      this.users = ordersData.users
      this.payments = ordersData.payments
      this.fastOrdersCount = ordersData.fastOrdersCount

      this.items = this.orders.map(order => {
        if (!order.finalState) {
          if (order.state === 3 || order.state === 5) {
            order._rowVariant = 'success';
          } else {
            if (order.deliveryTomorrow) order._rowVariant = 'warning';
            if (order.deliveryToday) order._rowVariant = 'danger';
            //if (order.deliveryBeforeToday) order._rowVariant = 'dark';
          }
          // Цвета в зависимости от статуса доставки имеют более высокий приоритет
          // if (order.deliveryClaimStatus === 19) order._rowVariant = 'info';
          if (order.deliveryClaimStatus === 4 && order.deliveryAvailable) order._rowVariant = 'primary';
        }

        return {
          ...order,
          // link with full objects
          createBy: this.users[order.createBy.id],
          updateBy: this.users[order.updateBy.id],
          collectBy: order.collectBy ? this.users[order.collectBy.id] : null,
          customer: this.customers[order.customer.id],
          items: order.items.map(item => {
            item.product = this.products[item.product.id]
            return item
          }),

          // date from milliseconds
          createDateStr: new Date(order.createDate).toLocaleDateString(),
          deliveryDate: order.deliveryDate ? new Date(order.deliveryDate) : null,
          deliveryDateStr: order.deliveryDate ? new Date(order.deliveryDate).toLocaleDateString() : "???",

          // local time from array
          deliveryIntervalStart: formatLocalTime(order.deliveryIntervalStart),
          deliveryIntervalEnd: formatLocalTime(order.deliveryIntervalEnd),

          // link with descriptions
          contactTypeDescr: consts.customers.contactTypes[this.customers[order.customer.id].contactType].name,
          stateDesc: consts.orders.states[order.state],
          paymentDescr: consts.orders.paymentTypes[order.paymentType],
          deliveryTypeDescr: consts.orders.deliveryTypes[order.deliveryType],
          deliveryIntervalTypeDescr: consts.orders.deliveryIntervalTypes[order.deliveryIntervalType]
        }
      })
    },

    async fetchData() {
      let params = {tab: this.tabModel}
      if (this.customerFilter) params.customerId = this.customerFilter.id
      if (this.dateFilterFrom) params.deliveryDateFrom = this.dateFilterFrom
      if (this.dateFilterTo) params.deliveryDateTo = this.dateFilterTo
      if (this.selectedFlorist) params.collectBy = this.selectedFlorist.phone
      if (this.selectedCourier) params.courier = this.selectedCourier.value
      const res = await this.$api.get("order", params)
      this.preprocessOrdersData(res);
    },

    getDeliveryLink(item) {
      return 'https://dostavka.yandex.ru/account/cargo?order=' + item.deliveryClaimId;
    },

    add() {
      this.global.showAddOrder(null)
    },

    addFast() {
      this.global.showFastAddOrder()
    },

    addWithForm() {
      this.global.showAddOrderWithForm()
    },

    edit(item) {
      this.global.showAddOrder(item)
    },

    changeState(item) {
      this.global.showChangeState(item)
    },
    showPayments(item) {
      const payments = this.payments[item.id]
      this.global.showPayments(item, payments)
    },

    linkWithYandexGo(item) {
      this.global.linkWithYandexGo(item)
    },

    showCancelDeliveryDialog(item) {
      this.global.cancelAutoDelivery(item)
    },

    showChangeCourier(item) {
      this.global.changeCourier(item)
    },

    async toggleDelivery(item) {
      const currentStatus = item.deliveryClaimStatus
      if (currentStatus === 19) {
        item.deliveryClaimStatus = 10
      }
      if (currentStatus === 10) {
        item.deliveryClaimStatus = 19
      }
      await this.$api.post("order", item)
    },

    changeCollector(item) {
      this.global.showChangeCollector(item)
    },

    viewCustomer(customer) {
      store.customers.showViewCustomer(customer)
    }

  }
}
</script>


